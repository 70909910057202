<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            :key="pictureurl"
            rounded
            :src="'https://api.herdoncloud.com/Company_Pictures/' + pictureurl"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          {{ $t("upload") }}
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!--/ reset -->
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group :label="$t('currencySymbol')" label-for="currency">
            <b-form-input
              v-model="companySettings.symbol"
              placeholder="currency"
              name="currency"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('ingredientPrecision')" label-for="symbol">
            <v-select
              v-model="companySettings.ingredientdecimal"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="valueOptions"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('pricePrecision')" label-for="pricedecimal">
            <v-select
              v-model="companySettings.pricedecimal"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="valueOptions"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('nutrientPrecision')"
            label-for="nutrientdecimal"
          >
            <v-select
              v-model="companySettings.nutrientdecimal"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="valueOptions"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group :label="$t('symbolfront')" label-for="symbol">
            <b-form-checkbox
              id="symbol"
              v-model="companySettings.symbolinfront"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('forceProduct')" label-for="productRequired">
            <b-form-checkbox
              id="productRequired"
              v-model="companySettings.forceproduct"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateCompany"
          >
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormCheckbox,
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { getUserData } from "@/auth/utils";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      companySettings: {},
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
      pictureurl: "empty.jpg",
      valueOptions: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
      ],
    };
  },
  mounted() {
    var user = getUserData();
    this.companySettings = {
      symbol: user.currency,
      symbolinfront: user.symbolinfront,
      forceproduct: user.forceproduct,
      ingredientdecimal: user.ingredientdecimal,
      nutrientdecimal: user.nutrientdecimal,
      pricedecimal: user.pricedecimal,
    };
    this.pictureurl = user.companypictureurl;
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.companySettings.imgstr = base64;
        this.$http
          .put(
            `/Companies/UpdateCompanyLogo/${user.companyid}`,
            JSON.stringify(base64),
            config
          )
          .then((result) => {
            if (result.data.status == "OK") {
              this.pictureurl = result.data.statusdata + "?" + Math.random();
              user.companypictureurl =
                result.data.statusdata + "?" + Math.random();
              localStorage.setItem("userData", JSON.stringify(user));
            }
          });
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  methods: {
    async updateCompany() {
      var user = getUserData();
      var result = await this.$http.put(
        `/Companies/UpdateForceandSymbol/${user.companyid}`,
        this.companySettings
      );
      //string apiResponse = await response.Content.ReadAsStringAsync();
      var isOK = result.status == 200;
      if (isOK)
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("updated", { type: this.$t("company") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notUpdated", { type: this.$t("company") }),
          },
        });
      user.currency = this.companySettings.symbol;
      user.pricedecimal = this.companySettings.pricedecimal;
      user.nutrientdecimal = this.companySettings.nutrientdecimal;
      user.ingredientdecimal = this.companySettings.ingredientdecimal;
      user.forceproduct = this.companySettings.forceproduct;
      user.symbolinfront = this.companySettings.symbolinfront;
      localStorage.setItem("userData", JSON.stringify(user));
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
